<template>
    <div class="training">
        <div class="banner">
            <div class="banner-text">Training</div>
            <img src="@/assets/banners/training_banner.jpg"/>
        </div>
        <b-row class="home-content clearfix pic-button">
            <b-col>
                <b-card-group deck>
                    <b-card class="about-card" title="Program #1" img-src="@/assets/sections/training_program_1.jpg" @click="picClicked('program1')"/>
                    <b-card class="about-card" title="Program #2" img-src="@/assets/sections/training_program_2.jpg" @click="picClicked('program2')"/>
                    <b-card class="about-card" title="Program #3" img-src="@/assets/sections/training_program_3.jpg" @click="picClicked('program3')"/>
                </b-card-group>
            </b-col>
        </b-row>
        <b-row class="home-content clearfix pic-button">
            <b-col>
                <b-card-group deck>
                    <b-card class="about-card" title="Program #4" img-src="@/assets/sections/training_program_4.jpg" @click="picClicked('program4')"/>
                    <b-card class="about-card" title="OJT Training" img-src="@/assets/sections/training_ojt.jpg" @click="picClicked('ojt')"/>
                    <b-card class="about-card" title="Mentor Training" img-src="@/assets/sections/training_ma.jpg" @click="picClicked('mentor')"/>
                </b-card-group>
            </b-col>
        </b-row>
        <b-row class="home-content">
            <b-col>
                <b-button variant="link" @click="expandAll">Expand All</b-button>
                <b-button variant="link" @click="collapseAll">Collapse All</b-button>
            </b-col>
        </b-row>
        <about-section ref="program1"
                      section="training-program-1"
                      class="home-content"
                      title="Program #1"
                      :visible="program1Visible"
                      v-on:section-clicked="program1Visible = !program1Visible">
            <p>
                The first program includes an orientation of the program and its learning
                objectives, as well as a range of course material including project delivery
                methods and contracts, leadership, communication, and safety. The program
                also includes two joint sessions with participants and mentors, in which they
                select the on-the-job training and mentor sessions.  Selections are based on
                the participant's and company's needs.
            </p>
            <p>
                A comprehensive review of the roles, responsibilities, and attributes of the
                superintendent on the job site and within the construction firm is the central
                learning objectives of this initial session.  Also covered are the combined roles
                and objectives of the superintendent and the project manager as the foundation
                for a successful project.
            </p>
            <p>
                Discussion, exercises, and activities involving trainees and mentors are utilized
                to provide a clear understanding of the program.
            </p>
            <p><strong>Lesson Topics for Program One Include</strong></p>
            <ul>
                <li>SCT Program Introduction</li>
                <li>Communication</li>
                <li>Project Delivery</li>
                <li>Procurement and Contracts</li>
                <li>Computing for the Superintendent</li>
                <li>Leadership</li>
                <li>Preparation for the Mentoring Process</li>
                <li>Safety and Risk Management</li>
                <li>Measuring Performance and Productivity</li>
                <li>Developing the Custom OJT and Mentoring Plan</li>
                <li>Ethics In Construction</li>
            </ul>
        </about-section>
        <about-section ref="program2"
                      section="training-program-2"
                      class="home-content"
                      title="Program #2"
                      :visible="program2Visible"
                      v-on:section-clicked="program2Visible = !program2Visible">
            <p>
                Program 2 includes a review of the participant since beginning the SCT program,
                including the formal OJT experiences and mentoring sessions. Specific interests
                and concerns will be addressed. A principal objective in this session is to
                sustain and expand the participant's field observation and understanding.
            </p>
            <p>
                Specific focus for Program 2 is on technical issues and IT skills. Key parts of
                the program focus on understanding and analyzing HVAC and plumbing systems, as
                well as mobile technologies and spreadsheet applications. The CITC provides the
                platform for these discussions and exercises.
            </p>
            <p><strong>Lesson Topics for Program Two Include:</strong></p>
            <ul>
                <li>Specialty Contractor Relationships</li>
                <li>Lean Construction Strategies</li>
                <li>Mobile Technologies</li>
                <li>HVAC Systems Interface</li>
                <li>Leadership: Self-Reflection</li>
                <li>Planning Strategies for Superintendent</li>
                <li>Construction Scheduling</li>
                <li>Spreadsheet Applications</li>
                <li>Scheduling Software Applications</li>
                <li>Plumbing Systems Interface</li>
                <li>Correspondence and Documentation</li>
            </ul>
        </about-section>
        <about-section ref="program3"
                      section="training-program-3"
                      class="home-content"
                      title="Program #3"
                      :visible="program3Visible"
                      v-on:section-clicked="program3Visible = !program3Visible">
            <p>
                Program 3 continues development of planning and problem-solving skills, as
                well as electrical and life safety systems. Emphasis is also placed on jobsite
                leadership and other management techniques.
            </p>
            <p><strong>Lesson Topics for Program Three Include:</strong></p>
            <ul>
                <li>Quality Management</li>
                <li>Meetings</li>
                <li>Problem Solving</li>
                <li>Sustainable Building</li>
                <li>Connecting the Estimate to the Jobsite</li>
                <li>Accounting for the Jobsite</li>
                <li>Electrical Systems Interface</li>
                <li>Change Management</li>
                <li>BMI for Construction</li>
                <li>Leadership and Motivation</li>
                <li>Document Control</li>
            </ul>
        </about-section>
        <about-section ref="program4"
                      section="training-program-4"
                      class="home-content"
                      title="Program #4"
                      :visible="program4Visible"
                      v-on:section-clicked="program4Visible = !program4Visible">
            <p>
                Program 4 continues focus on soft skills such as negotiations, presentations,
                ethics, and logistics. It also features a 'Capstone' session in which
                participant teams develop and present a comprehensive analysis of a project
                to an audience of mentors.
            </p>
            <p><strong>Lesson Topics in Program Four Include:</strong></p>
            <ul>
                <li>Negotiation Techniques</li>
                <li>Presentation Skills</li>
                <li>Life Safety Systems Interface</li>
                <li>Crisis Management</li>
                <li>Project Closeout</li>
                <li>Leadership and Communication</li>
                <li>Jobsite Management and Logistics</li>
                <li>MEP Execution Interface</li>
                <li>Capstone/Problem Solving</li>
                <li>Personal Management</li>
            </ul>
        </about-section>
        <about-section ref="ojt"
                      section="training-ojt"
                      class="home-content"
                      title="OJT Training"
                      :visible="ojtVisible"
                      v-on:section-clicked="ojtVisible = !ojtVisible">
            <p>
                The corporate sponsor will provide a minimum of 30 training experiences for
                the OJT component of the program, with the help of the mentor. Each experience
                is integrated in one or more of the SCT programs and the mentoring sessions.
                The mentor will coordinate and schedule the OJT learning experiences. All OJT
                records and Mentor reports will be kept by the mentor via the SCT website. The
                website address is given to each participant and their mentor when they attend
                their first class at the CITC.
            </p>
            <p><strong>Sample OJT Experiences include:</strong></p>
            <ul>
                <li>Prepare an agenda, and lead a project, safety, or scheduling meeting</li>
                <li>Attend a public speaking seminar or course</li>
                <li>Attend and observe (and participate in) an Owner-Architect-Contractor, OAC Meeting</li>
                <li>Shadow the General Superintendent (Manager of Operations) for a day</li>
                <li>Shadow a Project Manager for a day</li>
                <li>Review and Understand the collective bargaining agreements of each trade that is supervised</li>
                <li>Prepare and present for a project interview</li>
                <li>Attend a diversity training course</li>
                <li>Perform a complete accident investigation including any follow up meetings</li>
                <li>Plan and lead a critical pick preplanning meeting</li>
                <li>Prepare a crisis management plan for the project</li>
                <li>Prepare a purchase order for material or equipment</li>
                <li>Actively participate in assembling project close-out documentation</li>
                <li>Observe and assist during bid-day</li>
                <li>Prepare an estimate for a work package including: quantities, labor, material, and equipment</li>
                <li>Review shop drawings and submittals for a complete work package with installer</li>
                <li>Review and update a cost to complete/progress report</li>
                <li>Review and approve a monthly pay request</li>
                <li>Participate in layout as appropriate for your discipline/trade using any required horizontal and vertical control</li>
                <li>Prepare a storm water protection plan</li>
                <li>Coordinate and participate in a third-party inspection or JHA (Jurisdiction having authority) inspection</li>
                <li>Plan and manage the temporary power for a project</li>
                <li>Plan and participate in project commissioning</li>
                <li>Learn and utilize plan management software and use it for more than one month</li>
                <li>Learn and utilize project management software for more than one month</li>
                <li>Actively use a BIM model for reference during construction for more than one month</li>
                <li>Plan and manage the prefabrication of an assembly (on or off-site)</li>
                <li>Prepare and lead a pull planning scheduling session</li>
                <li>Review and understand the LEED scorecard for the project</li>
                <li>Prepare and manage a jobsite recycling program</li>
            </ul>
        </about-section>
        <about-section ref="mentor"
                      section="training-ma"
                      class="home-content"
                      title="Mentor Training"
                      :visible="mentorVisible"
                      v-on:section-clicked="mentorVisible = !mentorVisible">
            <p>
                This program has structured 15 mentor-trainee sessions, selected from a menu of 30+ opportunities.
                These sessions will provide the opportunity to focus on corporate policies and culture regarding
                the roles and responsibilities of the superintendent. Each session is supported by a SCT-developed
                exercise.
            </p>
            <p><strong>Suggested Mentor Discussion Topics Include:</strong></p>
            <ul>
                <li>Change Management</li>
                <li>Communication</li>
                <li>Technology Adoption</li>
                <li>LEAN Practices</li>
                <li>Sustainability</li>
                <li>Contract Documents</li>
                <li>Cost Management / Control</li>
                <li>Ethics</li>
                <li>Jobsite Safety / Environmental Concerns</li>
                <li>HR Policies</li>
                <li>Managing Disagreement</li>
                <li>Personnel Management</li>
                <li>Productivity</li>
                <li>Project Documentation</li>
                <li>Superintendent as Estimator</li>
            </ul>
        </about-section>
        <b-row class="home-content">
            <b-col>
                <hr/>
                <strong>The classes meet for four sessions, once every six months.</strong>
                <ul>
                    <li>The February class meets February - July - February - July.</li>
                    <li>The May class meets May - October - May - October.</li>
                    <li>The June class meets June - December - June - December.</li>
                </ul>
                <table class="table table-striped table-hover">
                    <template v-for="calendarYear in programs">
                        <thead>
                        <tr>
                            <th colspan="2">{{calendarYear.year}}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(program, key) in calendarYear.programs">
                            <td>{{key}}</td>
                            <td>{{ program | programsText }}</td>
                        </tr>
                        </tbody>
                    </template>
                </table>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import AboutSection from '@/components/public/AboutSection.vue';
import store from '@/store/store';
import _ from 'underscore';
import formatters from '@/util/formatters';
import {sprintf} from 'sprintf-js';
import { addYears } from 'date-fns';
import {PicClicked} from '@/util/pic_clicked';
import {errorToastOptions} from "../../util/formatters";

@Component({

    mixins: [PicClicked],

    components: {
        AboutSection
    },
    filters: {
        programsText: (programs) => {

            const indices = _.chain(programs).pluck('idx').unique().sortBy((i) => i).value();

            switch (indices.length) {
                case 1:
                    return sprintf('Program %d', indices[0]);
                case 2:
                    return sprintf('Programs %s', indices.join(' & '));
                case 4:
                    return sprintf('Programs 1 through 4');
                case 3:
                    return indices[0] === 1 && indices[2] === 4 ? sprintf('Programs %d, %d & %d',
                        indices[0],
                        indices[1],
                        indices[2]
                    ) : sprintf('Programs %d through %d',
                        indices[0],
                        indices[2]
                    );

                default:
                    console.log('Bad indices! %s', JSON.stringify(indices));
                    return '';
            }
        }
    }
})
export default class Training extends Vue {

    program1Visible = false;
    program2Visible = false;
    program3Visible = false;
    program4Visible = false;
    ojtVisible = false;
    mentorVisible = false;

    get programs() {

        const now = new Date();
        const nextYear = addYears(now, 1);
        const years = [now.getFullYear(), nextYear.getFullYear()];
        return _.map(years, (year) => {

            return {
                year,
                programs: _.chain(this.$store.getters['tracks/getProgramsByYear'](year))
                    .map((p) => {

                        const idx = p.idx;
                        const start = p.dates[0];
                        const end = p.dates[1];

                        const key = sprintf('%s %s-%s',
                            formatters.date(start, 'MMMM'),
                            formatters.date(start, 'd'),
                            formatters.date(end, 'd')
                        );

                        return { idx, start, end, key };
                    })
                    .groupBy((p) => p.key)
                    .value()
            };
        });
    }

    expandAll() {
        this.program1Visible = true;
        this.program2Visible = true;
        this.program3Visible = true;
        this.program4Visible = true;
        this.ojtVisible = true;
        this.mentorVisible = true;
    }

    collapseAll() {
        this.program1Visible = false;
        this.program2Visible = false;
        this.program3Visible = false;
        this.program4Visible = false;
        this.ojtVisible = false;
        this.mentorVisible = false;
    }

    async beforeRouteEnter(to, from, next) {
        try {
            await store.dispatch('tracks/loadTracks');
        }
        catch (error) {
            const vm = new Vue();
            vm.$bvToast.toast(error.message, errorToastOptions);
        }
        next();
    }
}
</script>
<style scoped>

</style>
